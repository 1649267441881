import React from "react";
import Layout from "../../layouts";

import './style.css'

const PrivacyPolicyEn = () => {
    return (
        <Layout children="en" showNav={false}>
            <h1>Wave Budget – Privacy Policy</h1>
            <p>
                Stefan Galler developed this app to support its users by tracking there personal expenses
            </p>

            <h2>Data collection</h2>
            <p>
                All data that is entered into the app is stored in the cloud using Firebase Firestore (see also "Third Parties").
            </p>

            <h2>Log Files</h2>
            <p>
                In case of an app crash, data connected to this crash is stored in log files. To be able to improve the app and
                remove the cause of the crash this data will be processed with Firebase Crashlytics (see also "Third Parties").
            </p>

            <h2>Statistics</h2>
            <p>
                To improve the quality of the app, usage data is collected to derive user statistics. These Statistics will be
                stored for 2 months. Aggregate statistics (sums, averages,...) will be stored longer.
            </p>

            <h2>Third Parties</h2>
            <p> Following third party services are used:</p>

            <h3>Firebase Authentication</h3>
            <p>
                This service is used for authenticating the user within the app. Following personal data is stored:
            </p>
            <ul>
                <li>passwords</li>
                <li>email addresses</li>
                <li>phone numbers</li>
                <li>user agents</li>
                <li>IP addresses</li>
            </ul>
            <p>Firebase Authentication keeps logged IP addresses for a few weeks. It retains other authentication information until
                the Firebase customer initiates deletion of the associated user, after which data is removed from live and backup
                systems within 180 days.</p>


            <h3>Firebase Crashlytics</h3>
            <p>This service is used to capture crashes and errors that occur when using the app. Following personal data is
                stored:</p>
            <ul>
                <li>Crashlytics Installation UUIDs</li>
                <li>Crash traces</li>
                <li>Breakpad minidump formatted data</li>
            </ul>
            <p>Firebase Crashlytics retains crash stack traces, extracted minidump data, and associated identifiers (including
                Crashlytics Installation UUIDs) for 90 days.</p>

            <h3>Google Analytics for Firebase</h3>
            <p>This service collects usage data from the app. Following personal data is stored:</p>
            <ul>
                <li>Firebase installation IDs</li>
                <li>Analytics App Instance IDs</li>
            </ul>
            <p>Event data is stored for 2 months.</p>

            <h3>Firestore</h3>
            <p>This service is used to store app data in the cloud.</p>
            <p>The app data is stored until the user account is deleted.</p>


            <h2>Your Rights</h2>
            <p>
                With regard to your data processed by us, you generally have the right for information, correction,
                deletion, restriction, data portability, revocation and objection. If you believe the processing
                your data violates data protection law or your data protection claims in any other way
                have been violated, you can complain to me (see contact) or the data protection authority.
            </p>

            <h2>Contact</h2>
            <p>
                In case of any questions feel free to contact me: galler.stefan@gmail.com.
            </p>
        </Layout>
    )
}

export default PrivacyPolicyEn